footer {
    z-index: 999;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 22px;
    padding-bottom: 21px;
    overflow: hidden;

}

.footer-content {
      margin: 0 auto;

}

.footer-base {
    padding-top: 20px;
    color: #EA9DAE;
    font-size: 12px;
}

.footer-legal {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
}

.footer-legal-copyright {
    margin-right: 30px;
    float: left;
    margin-top: 3px;
}

.footer-legal-links {
    float: left;
    display: flex;
    text-align: center;
    align-items: center;
}

.footer-legal-links a {
    margin-right: 10px;
    padding-right: 12px;
    display: inline-block;
    margin-top: 3px;
    white-space: nowrap;
    
    text-align: center;
    align-items: center;
}

.socialiconsfooter {
    height: 40px;
    width: auto;   
}