@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: 'sugarmagic';
  src: url(./sugarmagicpersonaluseonly-jemyo.otf) format('opentype');
 }

html,
#root {
  font-family: 'Outfit', "Roboto", "sugarmagic";
  font-weight: 200;
  background-color: #45151B;
  color: #EA9DAE;
  margin: 0;
}
 @font-face {
  font-family: 'sugarmagic';
  src: url(./sugarmagicpersonaluseonly-jemyo.otf) format('opentype');
 }

.MuiBox-root,
.MuiTypography-root {
  font-family: 'Outfit', "Roboto", "sugarmagic";
}

li {
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  
    @media ((min-width: 601px) and (max-width: 1099px)) {
     font-size: 1.3rem;
    }
  
    @media (min-width: 1100px) {
      font-size: 1.3rem;
    } }


p {
    font-size: 1rem;
  }



  @media (max-width: 600px) {
    html {

    margin-left: 2vh;
    margin-right: 2vh;
  }

  h1 {
      font-family: "sugarmagic";
      font-size: 1.5rem;
    }


  h2 {

      font-size: 1.1rem;
      }

}

  @media ((min-width: 601px) and (max-width: 1099px)) {
    html {
    margin-left: 7vh;
    margin-right: 7vh;
  }

  h1 {
    font-family: "sugarmagic";
    font-size: 2.5rem;
  }

  h2 {
    
    font-size: 1.3rem;
  }


}

  @media (min-width: 1100px) {
    html {
    margin-left: 15vh;
    margin-right: 15vh;
  } 

  h1 {
    font-family: "sugarmagic";
    font-size: 3.5rem;
  }

  h2 {
    
    font-size: 1.5rem;
  }

  
  
}
